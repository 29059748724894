import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { RouteConstant } from "../constants/RouteConstants";
import NotFound from "@pages/NotFound";

const Home = React.lazy(() => import("@pages/Home"));
const Causes = React.lazy(() => import("@pages/Causes"));
const Company = React.lazy(() => import("@pages/Company"));
const Blogs = React.lazy(() => import("@pages/Blogs"));
const ForNonProfits = React.lazy(() => import("@pages/ForNonProfits"));
const HowItWorks = React.lazy(() => import("@pages/HowItWorks"));
const Press = React.lazy(() => import("@pages/Press"));
const Charities = React.lazy(() => import("@pages/Charities"));
const SearchedCharities = React.lazy(() => import("@pages/SearchedCharities"));
const DonateInCharity = React.lazy(() => import("@pages/DonateInCharity"));
const ContactUs = React.lazy(() => import("@pages/ContactUs"));
const Faq = React.lazy(() => import("@pages/Faq"));
const PrivacyPolicy = React.lazy(() => import("@pages/PrivacyPolicy"));
const TermsAndCondition = React.lazy(() => import("@pages/TermsAndCondition"));
const BlogDetails = React.lazy(() => import("@pages/BlogDetails"));
const Login = React.lazy(() => import("@pages/Login"));
const Signup = React.lazy(() => import("@pages/Signup"));
const Verification = React.lazy(() => import("@pages/Verification"));
const ForgotPassword = React.lazy(() => import("@pages/ForgotPassword"));
const ResetPassword = React.lazy(() => import("@pages/ResetPassword"));

function AppRouter(props) {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Suspense fallback={<>...</>}>
            <Home />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.CAUSES}
        element={
          <Suspense fallback={<>...</>}>
            <Causes />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.COMPANY}
        element={
          <Suspense fallback={<>...</>}>
            <Company />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.BLOGS}
        element={
          <Suspense fallback={<>...</>}>
            <Blogs />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.FOR_NON_PROFITS}
        element={
          <Suspense fallback={<>...</>}>
            <ForNonProfits />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.HOW_IT_WORKS}
        element={
          <Suspense fallback={<>...</>}>
            <HowItWorks />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.PRESS}
        element={
          <Suspense fallback={<>...</>}>
            <Press />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.CHARITIES}
        element={
          <Suspense fallback={<>...</>}>
            <Charities />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.SEARCHED_CHARITIES}
        element={
          <Suspense fallback={<>...</>}>
            <SearchedCharities />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.DONATE_IN_CHARITY}
        element={
          <Suspense fallback={<>...</>}>
            <DonateInCharity />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.CONTACT_US}
        element={
          <Suspense fallback={<>...</>}>
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.FAQ}
        element={
          <Suspense fallback={<>...</>}>
            <Faq />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.PRIVACY_POLICY}
        element={
          <Suspense fallback={<>...</>}>
            <PrivacyPolicy />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.TERMS_AND_CONDITION}
        element={
          <Suspense fallback={<>...</>}>
            <TermsAndCondition />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.BLOG_DETAILS}
        element={
          <Suspense fallback={<>...</>}>
            <BlogDetails />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.LOGIN}
        element={
          <Suspense fallback={<>...</>}>
            <Login />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.SIGNUP}
        element={
          <Suspense fallback={<>...</>}>
            <Signup />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.VERIFICATION}
        element={
          <Suspense fallback={<>...</>}>
            <Verification />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.FORGOT_PASSWORD}
        element={
          <Suspense fallback={<>...</>}>
            <ForgotPassword />
          </Suspense>
        }
      />
      <Route
        path={RouteConstant.RESET_PASSWORD}
        element={
          <Suspense fallback={<>...</>}>
            <ResetPassword />
          </Suspense>
        }
      />

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRouter;
