export const RouteConstant = {
  HOME: "/",
  CAUSES: "/causes",
  COMPANY: "/company",
  BLOGS: "/blogs",
  BLOG_DETAILS: "/blog-details/:slug",
  ABOUT_US: "/about-us",
  FOR_NON_PROFITS: "/for-non-profits",
  HOW_IT_WORKS: "/how-it-works",
  PRESS: "/press",
  CHARITIES: "/charities",
  SEARCHED_CHARITIES: "/searched-charities",
  DONATE_IN_CHARITY: "/donate-in-charity",
  CONTACT_US: "/contact-us",
  FAQ: "/faq",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_AND_CONDITION: "/terms-and-condition",
  LOGIN_NON_PROFIT: "https://coinupapp.com/organization/login",
  REGISTER_NON_PROFIT: "https://coinupapp.com/organization/registration",
  LOGIN: "/login",
  SIGNUP: "/signup",
  VERIFICATION: "/verification",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password"
};
