import "./App.scss";
import AppRouter from "./router/AppRouter";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <BrowserRouter basename={"/"}>
      <AppRouter></AppRouter>
    </BrowserRouter>
  );
}

export default App;
