import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ReactGA from "react-ga4";

ReactGA.initialize("G-RY7N43BNR0");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
