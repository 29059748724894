import React from "react";

const NotFound = props => {
  return (

    <div className="">
      <h1 className="text-center">404 not found</h1>
    </div>

  );
};

export default NotFound;
